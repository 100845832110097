<template>
  <div :class="`m-3 p-2 wrapper  wrapper--${bu}`">
    <Loading v-if="loading" />
    <table class="w-100 table--perweek" :class="`table__border--${bu}`" v-if="!loading">
      <thead :class="`table__head--${bu}`">
        <tr>
          <th class="table__head--text">Nummer</th>
          <th class="table__head--text">Omschrijving</th>
          <th class="table__data--right table__head--text">Jaar</th>
          <th class="table__data--right table__head--text">Status</th>
          <th class="table__data--right table__head--text">Stadagen</th>
          <th class="table__data--right table__head--text">Stadagen V</th>
          <th class="table__data--right table__head--text">%</th>
          <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__head--text">Waarde</th>
          <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__head--text">Vraagprijs</th>
          <th class="table__data--right table__head--text">Afschrijving</th>
          <th class="table__data--right table__head--text">Verkoper</th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, key) in stock_position_writeoff_details"
          :key="key"
          class="table__row--color"
        >
          <td class="table__cell--default">
            <ImageHover :itemnummer="item.nummer" :bu="bu" />
          </td>
          <td class="table__cell--default">{{ item.omschrijving }}</td>
          <td class="table__data--right table__cell--default">{{ item.regjaar }}</td>
          <td class="table__data--right table__cell--default">{{ item.status }}</td>
          <td class="table__data--right table__cell--default">{{ item.dagen }}</td>
          <td v-if="item.status == 'V'" class="table__data--right table__cell--default">{{ item.dagenV }}</td>
          <td v-else class="table__data--right table__cell--default"></td>
          <td class="table__data--right table__cell--default">{{ item.percentage }}%</td>
          <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__cell--default">€ {{ item.waarde }}</td>
          <td v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__cell--default">€ {{ item.vraagprijs }}</td>
          <td class="table__data--right table__cell--default">€ {{ item.Afschrijving }}</td>
          <td v-if="item.status == 'V'" class="table__data--right table__cell--default">{{ item.verkoper }}</td>
          <td v-else class="table__data--right table__cell--default"></td>
        </tr>
      </tbody>
      <tfoot class="table__foot">
        <th class="table__foot--padding">Totaal:</th>
        <th class="table__foot--padding"></th>
        <th class="table__foot--padding"></th>
        <th class="table__foot--padding"></th>
        <th class="table__data--right table__foot--padding">
          {{ stock_position_writeoff_details_sum.stadagen }}
        </th>
        <th class="table__foot--padding"></th>
        <th class="table__data--right table__foot--padding"></th>
        <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__foot--padding">
          € {{ stock_position_writeoff_details_sum.waarde }}
        </th>
        <th v-if="checkroles(['marges_zien'], msalInstance.getAllAccounts()[0].idTokenClaims.roles)" class="table__data--right table__foot--padding">
          € {{ stock_position_writeoff_details_sum.vraagprijs }}
        </th>
        <th class="table__data--right table__foot--padding">
          € {{ stock_position_writeoff_details_sum.afschrijving }}
        </th>
        <th class="table__foot--padding"></th>
      </tfoot>
    </table>
  </div>
</template>

<script>
import request from "@/functions/request.js";
import Loading from "@/components/Loading.vue";
import ImageHover from "@/components/ImageHover.vue";


export default {
  props: ["bu", "group", "status"],
  components: { Loading, ImageHover },
  data: () => ({
    stock_position_writeoff_details: null,
    stock_position_writeoff_details_sum: null,
    loading: true,
  }),
  created() {
    this.getData(this.bu, this.group, this.status);
  },
  methods: {
    getData(bu, group, status) {
      request(
        `stock-position-writeoff-details/${bu}/${group}/${status}`,
        "GET"
      ).then(
        ({
          stock_position_writeoff_details,
          stock_position_writeoff_details_sum,
        }) => {
          this.stock_position_writeoff_details =
            stock_position_writeoff_details;
          this.stock_position_writeoff_details_sum =
            stock_position_writeoff_details_sum;
          this.loading = false;
        }
      );
    },
  },
};
</script>

<style></style>
